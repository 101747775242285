import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Products & Services | AVE AGRO FARM';

},[]);



  return (
    <section className='section-cover' >
           <div className='content-container'>
                <h2 className="home-h2">Products & Services</h2>
                <p className='products-services-intro'>We deliver quality products to our clients. We have a team of dedicated staff who are friendly and knowledgeable about our work. We deal in the following:</p>
                {/* <h4 className='staff-title staff-title-a staff-title-b'>Products</h4> */}
                <div className='team-members'>
                   <article className='team-member'>
                    {/* <h3 className='staff-name'>David Kilama</h3>
                    <img src='./images/9.jpg' alt='photo' className='staff-member'></img> */}
                    <h4 className='staff-title'>Products</h4>
                    {/* <p className='staff-details'>David graduated from Gulu University, Uganda with a Bacherlor degree in Biosystems Engineering in 2020. Upon joining the company as a NRMO in 2020, he has been very reliable and working very hard to probel the company in the right direction, his skills are unmatched. </p> */}
                   <ul className='services-list'>
                    <li>Farm tools.</li>
<li>Seeds (Assorted).</li>
<li>	DOCs layers, broilers and Kuroilers.</li>
<li>Farm Equipment.</li>
<li>	Animal feeds.</li>


                   </ul>
                   </article>
                   <article className='team-member'>
                    {/* <h3 className='staff-name'>David Kilama</h3>
                    <img src='./images/9.jpg' alt='photo' className='staff-member'></img> */}
                    <h4 className='staff-title'>Services</h4>
                    {/* <p className='staff-details'>David graduated from Gulu University, Uganda with a Bacherlor degree in Biosystems Engineering in 2020. Upon joining the company as a NRMO in 2020, he has been very reliable and working very hard to probel the company in the right direction, his skills are unmatched. </p> */}
                   <ul className='services-list'>
                    <li>Installation and maintenance of irrigation equipment.</li>
<li>Agro-Vet trainings.</li>
<li>Extension works in Agriculture. </li>
<li>Agribusiness Skills training.</li>

                   </ul>
                   
                   </article>
                   

                </div>
                {/* <h4 className='staff-title staff-title-a'>Services</h4>
           <div className='team-members'>
                   <article className='team-member'>
                    <h3 className='staff-name'>Ben Lakony</h3>
                    <img src='./images/18.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Chairperson Board of Directors</h4>
                    <p className='staff-details'>Ben is a very experienced person who is helping to organise and ensure smooth runing of the company. He has a Bachelor's degree in development studies and a diploma in counselling and guidance.</p>
                   </article>

                   
                </div> */}
          </div>   
    </section>
  )
}

export default Clients