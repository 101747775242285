import Carousel from 'react-bootstrap/Carousel';

function MaintCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/23.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
     
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/21.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/36.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/5.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/6.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/9.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/11.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/12.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/13.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/14.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/15.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/16.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

    </Carousel>
  );
}

export default MaintCarousel;