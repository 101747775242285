import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Support & Managed Services | RAK ENGINEERING LTD';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
              
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Support & Managed Services</h3>
                     <p>Our Support & Managed Services are designed to ensure optimal business outcomes while mitigating the risk of maintaining and running complex systems. We enable customers to focus on their core business while entrusting us with system support, operations and maintenance of complex ICT and Power Systems. </p>
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>

           
           </div>

    </section>
  )
}

export default Services