import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';


const Services = () => {

useEffect(()=>{
document.title = "Our Services | Spelac Electrical Consults Ltd";

},[]);

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">Our Services</h2>
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Electrical engineering consultancy services</h3>
           <div className='rhombus'></div>
                     <p>
    Our experienced electrical team consists of professional engineers with experience designing and developing electrical systems and control systems. Our electrical engineers and designers participate in all project stages, from studies through detailed engineering, as required. We work on pilot plant designs for start-up technologies, to large-scale brownfield and greenfield processing facilities. As industrial engineering consultants, clients benefit from our wide range of experience providing fit-for-purpose industrial designs.
                   
                     </p>
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>
                     Testing and commissioning of electrical installations and appliances
                      </h3>
                      <div className='rhombus'></div>
                     
                     <p>
               Here we verify, document, and place into service newly installed or retrofitted electrical power equipment and systems. We assure that tested electrical systems are safe, reliable, perform within manufacturer tolerances, are installed in accordance with design specifications, and conform to applicable standards.
                     </p>
                     <p>
              Acceptance testing occurs concurrently with commissioning. It is the field-testing phase in which individual electrical components are assessed to verify their suitability for initial energization. Our field tests and inspections are conducted to assure that the equipment and systems are as specified in the original design documents, meet manufacturer specifications, and are installed correctly.
                     </p>
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply of industrial electrical components i.e. instrumentation, control electronics, PLCs, battery banks and electrical machinery</h3>
                       <div className='rhombus'></div>
                        <p>
              
                     Our industrial electrical supply is backed up by excellent services, allowing us to be a reliable supplier for our customers. We provide excellent engineered solutions that aid in the avoidance of unplanned stops, enabling improved productivity in our clients’ operations. 
                     </p>
                    

                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>
                      Supply of industrial and commercial electrical control and protection devices like contactors and circuit breakers
                      </h3>
                      <div className='rhombus'></div>
                    <p>When it comes to electrical devices like conduit, enclosures, switches, contactor, circuit breakers, relays, etc. We are your one-stop supplier. We have cultivated amazing relationships with quality industrial manufacturers.</p>
                   
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>
            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>
                      Installation and maintenance of industrial, residential and commercial electrical establishments
                      </h3>
                       <div className='rhombus'></div>
                        <p>
        Our company offers electrical installation and maintenance works with experience in a wide range of industries, such as construction and engineering. We are proud to offer professional, custom-made electrical solutions tailored to the individual needs of our clients. We are able to offer electrical installation and maintenance services from small electrical installation and maintenance of residential units, home to electrical installations and maintenance in industrial sectors such as manufacturing plants and the automotive industry.
                     
                     </p>
                     

                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Low(240-415v) and medium voltage(33kv) power line survey and design</h3>
                    <div className='rhombus'></div>
                    <p>
                      Our designs are adapted to the needs of each project: from medium voltage and low voltage in rural networks, using concrete or wooden poles.
                    </p>
                   
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div>
            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Power plant electrical installations specialist in solar and hydro</h3>
                       <div className='rhombus'></div>
                        <p>
                     Here our services include the following:
                        </p>
                        <ul>
                          <li>Installation of power station electrical systems.</li>
<li>Installation of sub stations(HV and MV).</li>
<li>Installation of distribution switch boards and machine control panels.</li>
<li>Lighting protection.</li>
<li>Earthing works.</li>
<li>Electrical wiring for commercial, industrial and domestic buildings.</li>
<li>Transmission line construction LV, MV and HV.</li>
<li>Assembling, maintenance and repair of electrical and electronic equipment.</li>
<li>Solar power installations.</li>

                        </ul>
                     

                  </article>

                  <article className='service-slides'>
                    <BuildingsCarousel></BuildingsCarousel>
                 
                  </article>     
            </div>
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Industrial automation i.e. PLCs and SCADA installation and commissioning</h3>
                   <div className='rhombus'></div>
                   <p>Faster, more accurate production is achieved through automation. We provide custom industrial automation services for a variety of plant applications across all major technology platforms and most industries. Our proven approach to automation design and implementation has helped us create lasting competitive advantage for our clients over the years.</p>
                   <p>Our goal with any automation project is to deliver superior results for you while helping you prove the long-term ROI of your investment. </p>
                  </article>

                  <article className='service-slides'>
                    <Plumbing></Plumbing>
                 
                  </article>     
            </div>
             <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Energy audit analysis</h3>
                        <div className='rhombus'></div>
                        <p>
        An energy audit is an inspection, survey and analysis of energy flows for energy conservation in a building, process or system. The purpose of conducting an energy audit is to reduce the amount of energy input into the system without negatively affecting the output(s). Beyond simply identifying the sources of energy use, an energy audit seeks to prioritize the energy uses according to the greatest to least cost effective opportunities for energy savings.    
                     </p>
                    <p>Under Energy audit analysis, we do a broad spectrum of energy studies ranging from a quick walk-through of a facility to identify major problem areas to a comprehensive analysis of the implications of alternative energy efficiency measures.</p>

                  </article>

                  <article className='service-slides'>
                    <AC></AC>
                 
                  </article>     
            </div>



           </div>

    </section>
  )
}

export default Services