import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Power Line Design & Construction | RAK ENGINEERING LTD';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
              

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Power Line Design & Construction</h3>
                     <p>We offers construction of high voltage power structures (substations, power transmission lines) and repair works. During project implementation, the company staff guarantees high quality of work and professional approach to each client. Over the years, we have built a number of distribution and low-voltage transmission lines including projects that are overhead, underground and straight through town. Our power services include the following:</p>
                     <ul>
                      <li>High Voltage Transmission</li>
                      <li>Substation Construction</li>
                      <li>Power Distribution</li>
                       <li>Power Line Maintenance</li>
                     
                     </ul>
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

           

            
           </div>

    </section>
  )
}

export default Services