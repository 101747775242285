import React, {useEffect} from 'react'
import CarouselFade from '../components/Agronomy';
import {Link} from 'react-router-dom';
import MissionCarousel from '../components/Mission Carousel';
import {FaArrowAltCircleRight,FaQuoteLeft,FaUsers,FaHandshake} from 'react-icons/fa';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Agronomy | AVE AGRO FARM";

},[]);

  return (
    <>
    <section className='section-cover' >
           <div className='content-container'>
                <h2 className="home-h2">Agronomy</h2>
                <p className='products-services-intro'>Training activities carried out with farmers for good agronomic practices in vegetable production.</p>     
          </div>   
    </section>
    <section>
      <CarouselFade></CarouselFade>
    </section>
    </>
  )
}

export default Home