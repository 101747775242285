import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Our Team | AVE AGRO FARM';

},[]);



  return (
    <section className='section-cover' >
           <div className='content-container'>
                <h2 className="home-h2">Our Team</h2>
                <h4 className='staff-title staff-title-a staff-title-b'>Staff Profile</h4>
                <div className='team-members'>
                   <article className='team-member'>
                    <h3 className='staff-name'>David Kilama</h3>
                    <img src='./images/9.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Natural Resources Management Officer</h4>
                    <p className='staff-details'>David graduated from Gulu University, Uganda with a Bacherlor degree in Biosystems Engineering in 2020. Upon joining the company as a NRMO in 2020, he has been very reliable and working very hard to propel the company in the right direction, his skills are unmatched. </p>
                   </article>
                   <article className='team-member'>
                    <h3 className='staff-name'>Anifa Basemera</h3>
                    <img src='./images/10.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Coordinator</h4>
                    <p className='staff-details'>Anifa has a Bachelor's degree in Agribusiness and Communications with good background in agronomy. She has good communiction skill that makes her easily coordiate her activities in the company.</p>
                   </article>
                   <article className='team-member'>
                    <h3 className='staff-name'>Yoweri Kidega</h3>
                    <img src='./images/11.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Accountant</h4>
                    <p className='staff-details'>Yoweri has a BA degree in Business Administration. His 4 years experience in accounting field with various organisations has gifted him with wider knowledge on accounting tasks. He is an agile fellow and willing to learn.</p>
                   </article>
                   <article className='team-member'>
                    <h3 className='staff-name'>Annet Amony </h3>
                    <img src='./images/12.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Agronomist</h4>
                    <p className='staff-details'>Annet has a Bsc. Agriculture. She is a senior extension worker with a very broad knowledge on practical skills. Ever since she join our company lots of brilliant ideas on crop production kept coming. </p>
                   </article>
                   <article className='team-member'>
                    <h3 className='staff-name'>Nancy Acan</h3>
                    <img src='./images/13.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Finance Manager</h4>
                    <p className='staff-details'>Nancy holds M.Sc. Agri-enterprises Development & Bsc. Agriculture with  a true love for agricultural enterprises development. She also bring a vast financial management knowledge to this company.</p>
                   </article>
                    <article className='team-member'>
                    <h3 className='staff-name'>Simon Okwera</h3>
                    <img src='./images/14.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Sales Manager</h4>
                    <p className='staff-details'>Msc. Agri-enterprises development, Bachelor's of Business adminstration and a diploma in SACCO and Microfinance management.</p>
                   </article>
                    <article className='team-member'>
                    <h3 className='staff-name'>Briton Kananura</h3>
                    <img src='./images/15.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Irrigation Engineer</h4>
                    <p className='staff-details'>Briton has a Bsc in Sustainable Agriculture and extension with a wide knowledge in handling irrigated agricultural production and irrigation equipment.</p>
                   </article>
                   <article className='team-member'>
                    <h3 className='staff-name'>Collins Nyeko</h3>
                    <img src='./images/16.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Field Facilitator</h4>
                    <p className='staff-details'>Collins is a very motivated agronomist working as field facilitator with a broader hands-on-skills of handling crops in the field at all stages of growth ie. Germination, development and maturity stages. Collins also has an understanding on harvesting and postharvest treatment of farm products.</p>
                   </article>
                    <article className='team-member'>
                    <h3 className='staff-name'>Andrew Ojok</h3>
                    <img src='./images/17.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Driver</h4>
                    <p className='staff-details'>Andrew has wide knowledge in driving and machine handling. His commitment high to ensure transportation is handled effectively. </p>
                   </article>

                </div>
                <h4 className='staff-title staff-title-a'>Board of Directors</h4>
           <div className='team-members'>
                   <article className='team-member'>
                    <h3 className='staff-name'>Ben Lakony</h3>
                    <img src='./images/18.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Chairperson Board of Directors</h4>
                    <p className='staff-details'>Ben is a very experienced person who is helping to organise and ensure smooth runing of the company. He has a Bachelor's degree in development studies and a diploma in counselling and guidance.</p>
                   </article>

                    <article className='team-member'>
                    <h3 className='staff-name'>Prossy Ayubu</h3>
                    <img src='./images/19.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Board Member</h4>
                    <p className='staff-details'>Works closely with the agro-institutions and learning firms to design and test work/entrepreneurship related activities and learning objectives that help young people to build their market relevant skills. </p>
                   </article>
                   <article className='team-member'>
                    <h3 className='staff-name'>Atwom Simon Word</h3>
                    <img src='./images/20.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Treasurer </h4>
                    <p className='staff-details'>A dynamic multi-skilled visionary, team player, leader and enthusiastic professional with proper all-around solid managerial skills, knowledge of business policies and processes, land surveying and mapping skills with more than 5 years of experiences.</p>
                   </article>
                     <article className='team-member'>
                    <h3 className='staff-name'>Alfred Kilama</h3>
                    <img src='./images/21.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Advisor - Public Relations</h4>
                    <p className='staff-details'>Alfred is 38 years old, a senior Agriculturalist. He's well presented, self motivated and confident agricultural and management graduate who has an extensive knowledge of how to successfully assess the needs of community and then develop and implement suitable plans.</p>
                   </article>
                   <article className='team-member'>
                    <h3 className='staff-name'>Ms. Ajok Susan Atare</h3>
                    <img src='./images/22.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>V/Chairperson Board </h4>
                    <p className='staff-details'>Susan is a very motivated and hard working lady with an accounting background. She has worked with reputable international NGOs in the country as an accountant for more than 8 years and a very enterprising person with business mind.</p>
                   </article>
                   <article className='team-member'>
                    <h3 className='staff-name'>Ass. Prof. Basil Mugonola</h3>
                    <img src='./images/23.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Board Member</h4>
                    <p className='staff-details'>An associate professor (PhD) Agricultural Economics, department of rural development and agri business, faculty of agriculture and environment, Gulu University. He has been in the field of Agribusiness for more than 10 years. </p>
                   </article>
                   <article className='team-member'>
                    <h3 className='staff-name'>Mr. Ariho Ferdinand Kagaba</h3>
                    <img src='./images/24.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Board Member</h4>
                    <p className='staff-details'>Ferdinand is a dynamic and results oriented social worker, with progressive experience in relationship  and customer service management. A good team player, self motivated and excellent communicator, and  God fearing. He can work in a high volume- high productivity environment. </p>
                   </article>
                   <article className='team-member'>
                    <h3 className='staff-name'>Juliet Akumu</h3>
                    <img src='./images/25.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Managing Director (MD)</h4>
                    <p className='staff-details'>MSc. Agri-enterprises Development, BSc. Agriculture & Dip. Crop production and Mgt and the managing director of the company.   </p>
                   </article>
                  

                </div>
          </div>   
    </section>
  )
}

export default Clients