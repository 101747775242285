import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/gs.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>

      
    </Carousel>
  );
}

export default PowerCarousel;