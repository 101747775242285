import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = "About Us | AVE AGRO FARM";

},[]);


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">About Us</h2>
      {/* <p className='about-intro-p'></p> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                      
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>
                             "To become a center of excellence in demonstrating agribusinesses and empowering farmers with practical skills and improving livelihoods."
                              </p>
                          
                          <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p>
                           "To provide agricultural inputs and become a model center of excellence where farmers become skilled change agents equipped with realistic modern farming skills using ecologically friendly and commercially sustainable approaches."
                              </p>

                    </article>

                    


                 </div>

                 <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                        <MissionCarousel></MissionCarousel>
                    </article>

                    <article className='vision-mission-info vision-mission-info1'>
                      
                          <h3 className='title-h3'>Our Core Values</h3>
                          
                           <div className='vm-line'></div>
                          <ul>
              <li>Integrity, Transparency and Accountability.</li>
 <li>Teamwork.</li>
 <li>Excellence.</li>
 <li>Flexibility.</li> 
 <li>Equity.</li>
 <li>High quality.</li>

                          </ul>
                          
                          

                    </article>

                    {/* <article className='vision-mission-info '>
<h3 className='title-h3'>Our Core Values</h3>
<div className='vm-line'></div>
           <CoreValues></CoreValues>

                    </article> */}


                 </div>
          </div>
    </section>
        {/* <section className='section-cover bg-cover'>
       
        <div className='content-container '>
          
          <h2 className="home-h2">Our Policies</h2>
          <article className='policies-container'>
             <div className='policy-text'>
                <h3>Quality Policy</h3>
<p className='home-p home-p-a'>
    We are committed to providing quality service in performing our duties in a professional manner with integrity and abiding by professional and legal requirements at all times.
  </p>

             </div>
              <div className='policy-image'>
                <img src='./images/118.png'></img>
             </div>

          </article>
          <article className='policies-container policies-container1'>
             <div className='policy-text'>
                <h3>Health & Safety Policy</h3>
<p className='home-p home-p-a'>
    We ensure the Health and Safety of our clients, employees and communities within which we work, using structured systems, processes and procedures.
  </p>

             </div>
              <div className='policy-image'>
                <img src='./images/119.jpg'></img>
             </div>

          </article>
          <article className='policies-container'>
             <div className='policy-text'>
                <h3>Environmental Policy</h3>
<p className='home-p home-p-a'>
   We strive to maintain and improve the level of environmental management by strictly complying with environment-related laws, ordinances and agreements in all aspects of our activities.
  </p>

             </div>
              <div className='policy-image'>
                <img src='./images/120.jpg'></img>
             </div>

          </article>

        </div>   

    </section> */}

    </>
  )
}

export default WhoWeAre